let $ = require('jquery');
let Barba = require('barba.js');
require('waypoints/lib/noframework.waypoints.min.js');

document.addEventListener('DOMContentLoaded', () => {
  
  var $siteContainer = $(".ccm-page"),
      $siteIntro = $(".site-intro"),
      $siteWrapper = $siteContainer.parent(),
      $siteOuterWrapper = $(".ccm-page-wrapper"),
      pageType = 'default',
      transitioning = false,
      activeTransition,
      site = {};
      
  let transitions = {
    'home-to-project': {durationIn: 500, durationOut: 500},
    'home-to-projects': {durationIn: 500, durationOut: 500},
    'home-to-default': {durationIn: 500, durationOut: 500},
    'project-to-home': {durationIn: 500, durationOut: 500},
    'project-to-project': {durationIn: 500, durationOut: 500},
    'project-to-projects': {durationIn: 500, durationOut: 500},
    'project-to-project-details': {durationIn: 500, durationOut: 500},
    'project-to-default': {durationIn: 500, durationOut: 500},
    'projects-to-home': {durationIn: 500, durationOut: 500},
    'projects-to-project-details': {durationIn: 500, durationOut: 500},
    'projects-to-default': {durationIn: 500, durationOut: 500},
    'next-project-to-project-details': {durationIn: 500, durationOut: 500},
    'next-project-to-home': {durationIn: 500, durationOut: 500},
    'project-details-to-project': {durationIn: 500, durationOut: 500},
    'project-details-to-projects': {durationIn: 500, durationOut: 500},
    'project-details-to-next-project': {durationIn: 500, durationOut: 500},
    'project-details-to-home': {durationIn: 500, durationOut: 500},
    'project-details-to-default': {durationIn: 500, durationOut: 500},
    'default': {durationIn: 500, durationOut: 500}
  };
  
  var nextPageType = 'default';
  
  $siteIntro.addClass('is-active');
  site.loaded = false;
  site.isMobile = $(window).width() < 600;
  site.isLoggedIn = $(".ccm-toolbar-visible").length > 0;
  site.isEditMode = CCM_EDIT_MODE;
  
  setTimeout(function() {
    
    //$siteIntro.one('animationiteration webkitAnimationIteration', function() {
      $siteIntro.addClass('is-finished');
    //});
    
    setTimeout(function() {
      $siteIntro.remove();
      $siteContainer.addClass('transition-in');
      $siteOuterWrapper.addClass('site-loaded');
      site.loaded = true;
    }, 500);
    
  }, 1500);
    
  site.$mouseTrack = $(".js-mouse-track");
  site.$mouseTrackFixed = $(".js-mouse-track-fixed");
  site.$mouseTrackText = $(".js-cursor-highlight-text");
  document.addEventListener('mousemove', onMouseUpdate, false);
  document.addEventListener('mouseenter', onMouseUpdate, false);
  
  initBarba();
  initPageType();
  
  function initPageType() {
    
    initPageBefore();
    
    if ($siteContainer.hasClass('page-type-home')) { //homepage
      pageType = 'home';
      initHomePage();
    } else if ($siteContainer.hasClass('page-type-project')) { //project
      pageType = 'project';
      initProjectPage();
    } else if ($siteContainer.hasClass('page-type-project-details')) { //project details
      pageType = 'project-details';
      initProjectDetailsPage();
    } else if ($siteContainer.hasClass('page-type-next-project')) { //next project
      pageType = 'next-project';
      initProjectPage();
    } else if ($siteContainer.hasClass('page-type-projects')) { //next project
      pageType = 'projects';
      initProjectPage();
    } else {
      pageType = 'default';
    }
    
    initPageAfter();
  }
  
  function initPageBefore() {
    initMouseTrack();
  }
  
  function initPageAfter() {
    
    var classNames = 'ccm-page-wrapper page-type-' + pageType;
    if (site.loaded) {
      classNames += ' site-loaded';
    }
    $siteOuterWrapper.attr('class', classNames);
    
    if (! site.isMobile) {
      initScrollNav();
    }
    
    initModals();
    
    initForms();
  }
  
  function initModals() {
    $(".js-btn-modal").click(function() {
      let modal = $(this).attr('data-modal');
      
      if (modal) {
        $(".modal[data-modal='" + modal + "']").addClass('is-open');
      }
      
    });
    
    $(".js-btn-modal-close").click(function() {
      let $modal = $(this).parents(".modal");
      
      $modal.removeClass('is-open');
      
      setTimeout(function() {
        $modal.removeClass('is-submitted').find(".is-submitted").removeClass('is-submitted');
      }, 500);
      
    });
  }
  
  function initForms() {
    $(".js-form").each(function() {
      
      $(this).submit(function() {
        let $this = $(this),
            $modal = $this.parents(".modal"),
            url = $this.attr('action');
            
        $.post(url, $this.serializeArray());
        
        $this.addClass('is-submitted');
        
        if ($modal.length) {
          $modal.addClass('is-submitted');
        }
        
        return false;
      });
      
    });
  }
  
  function initMouseTrack() {
    
    site.$mouseTrack.removeClass('is-active').removeClass('is-clicked');
    
    $("a, .js-a").off('mouseenter mouseleave').hover(function() {
      //get middle of hyperlink
      let $this = $(this),
          offset = $this.offset(),
          width = $this.outerWidth(),
          height = $this.outerHeight(),
          scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
      
      var centerX = offset.left + width / 2;
      var centerY = offset.top + height / 2;
      
      site.$mouseTrackFixed.css('--fixedX', centerX).css('--fixedY', centerY - scrollPos);
      $siteOuterWrapper.addClass('fixed-hover');
    }, function() {
      $siteOuterWrapper.removeClass('fixed-hover');
      site.$mouseTrackFixed.removeClass('is-clicked');
    }).off('click').click(function() {
      site.$mouseTrackFixed.addClass('is-clicked');
    });
    
    $(".js-cursor-highlight-image").off('mouseenter mouseleave').hover(function() {
      var text = $(this).attr('data-label') || 'Explore';
      site.$mouseTrackText.text(text);
      site.$mouseTrack.addClass('is-active');
    }, function() {
      site.$mouseTrack.removeClass('is-active');
    }).off('click').click(function() {
      site.$mouseTrack.removeClass('is-active').addClass('is-clicked');
    });
  }
      
  function onMouseUpdate(e) {
    site.$mouseTrack.removeClass('is-hidden');
    setMouseTrackPosition(e.pageX, e.pageY);
  }
  
  function setMouseTrackPosition(x, y) {
    let scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
      
    site.$mouseTrack.css('--x', x).css('--y', y - scrollPos);
  }
  
  function initHomePage() {
    
  }
  
  function initProjectPage() {
    
    site.$portfolioItemImage = $(".portfolio-item__image");
    
    site.$portfolioItemImage.click(function() {
      let $this = $(this),
          $hyperlink = $this.parents(".portfolio-item").find("[data-page-type='project-details']");
          
      nextPageType = $hyperlink.attr('data-page-type') || 'default';
      
      Barba.Pjax.goTo($hyperlink.attr('href'));
    });
  }
  
  function initProjectDetailsPage() {
    let $projectText = $(".project__text"),
        $projectVisual = $(".project__visual"),
        $projectSectionNavItem = $(".project__section-nav-item"),
        $visualOverlay = $(".visual-overlay"),
        $contactBar = $(".contact-bar"),
        $project = $(".project"),
        // $nextProjectDummy = $(".next-project-dummy"),
        projectVisualOffset;
        
    if ($projectVisual.length) {
      projectVisualOffset = $projectVisual.first().offset().top;
    } else {
      projectVisualOffset = 0;
    }
    
    var section = 1;
    
    if (site.isMobile) {
      
      let $projectVisuals = $(".project__visuals");
      
      $projectText.each(function() {
        let $this = $(this),
            projectSection = $this.attr('data-section');
            
        $this.addClass('is-mobile');
        
        var $sectionVisuals = $projectVisual.filter("[data-section=" + projectSection + "]");
        if ($sectionVisuals.length) {
          let $sectionVisualsContainer = $("<div />").addClass('project__visuals').attr('data-section', projectSection);
          $sectionVisualsContainer.append($sectionVisuals).insertAfter($this);
        }
        
      });
      
      $projectVisuals.remove();
      
      $(".project__visual").add($projectText).each(function() {
        let $this = $(this),
            projectSection = $this.attr('data-section');
        
        new Waypoint({
          element: $this[0],
          handler: function() {
            if (projectSection != section) {
              section = projectSection;
              activateSection(projectSection);
            }
          },
          offset: '50%'
        });
      });
      
    } else {
    
      $projectVisual.each(function() {
        let $this = $(this);
        
        new Waypoint({
          element: $(this)[0],
          handler: function() {
            let newSection = $this.attr('data-section') || section;
        
            if (newSection != section) {
              activateSection(newSection);
            }
          },
          offset: '50%'
        });
        
        
      }).click(function() {
        let $this = $(this),
            expandable = ! $this.hasClass('is-not-expandable');
            
        if (expandable) {
        
          let scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop,
              offset = $this.offset(),
              width = $this.outerWidth(),
              height = $this.outerHeight(),
              left = offset.left,
              top = offset.top - scrollPos,
              src = $this.attr('src');
              
          if (src) {
            //image
            $visualOverlay.css('background-image', 'url(' + src + ')');
          } else {
            //video
            $visualOverlay.append($this.html());
            var overlayVideo = $visualOverlay.find("> video")[0];
            overlayVideo.currentTime = $this.find("> video")[0].currentTime;
            overlayVideo.play();
            
          }
          
          $visualOverlay.css('left', left).css('top', top).css('width', width).css('height', height);
          
          $project.addClass('is-inactive');
          
          setTimeout(function() {
            $visualOverlay.addClass('is-active');
            
            $visualOverlay.off('click').click(function() {
              let scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop,
                offset = $this.offset(),
                width = $this.outerWidth(),
                height = $this.outerHeight(),
                left = offset.left,
                top = offset.top - scrollPos;
                
              $visualOverlay.addClass('is-idle').removeClass('is-active').removeClass('is-clickable').css('left', left).css('top', top).css('width', width).css('height', height);
              
              $project.removeClass('is-inactive');
              
              setTimeout(function() {
                site.$mouseTrack.removeClass('is-clicked');
              }, 500);
              
              setTimeout(function() {
                $visualOverlay.removeAttr('style').removeClass('is-idle');
                $visualOverlay.find("video").remove();
              }, 1000);
              
            });
            
          }, 100);
          
          setTimeout(function() {
            site.$mouseTrack.removeClass('is-clicked');
          }, 500);
          
          setTimeout(function() {
            $visualOverlay.addClass('is-clickable');
          }, 1000);
          
        }
      });
      
    }
    
    $projectVisual.each(function() {
      let $this = $(this);
      new Waypoint({
        element: $this[0],
        handler: function(direction) {
          let $video = $this.find("> video");
          if ($video.length) {
            if (direction == 'down') {
              $video[0].pause();
            } else {
              $video[0].play();
            }
          }
        }
      })
      
      new Waypoint({
        element: $this[0],
        handler: function(direction) {
          let $video = $this.find("> video");
          if ($video.length) {
            if (direction == 'down') {
              $video[0].play();
            } else {
              $video[0].pause();
            }
          }
        },
        offset: 'bottom-in-view'
      })
      
    });
    
    $projectSectionNavItem.click(function() {
      var scrollTop;
      let section = $(this).attr('data-section');
      
      if (section == 1) {
        scrollTop = 0;
      } else if (site.isMobile) {
        scrollTop = $projectText.filter("[data-section='" + section + "']").first().offset().top - 35;
      } else {
        scrollTop = $projectVisual.filter("[data-section='" + section + "']").first().offset().top - projectVisualOffset;
      }
      
      $("html, body").animate({
        scrollTop: scrollTop
      }, 500);
    });
  
    new Waypoint({
      element: $contactBar[0],
      handler: function(direction) {
        if (direction == 'down') {
          $project.addClass('out-view');
          
        } else {
          $project.removeClass('out-view');
        }
        
        setTimeout(function() {
          Waypoint.refreshAll();
        });
        
      },
      offset: '100%'
    });
    
    var t;
    
    // new Waypoint({
    //   element: $nextProjectDummy[0],
    //   handler: function(direction) {
    //     if (direction == 'down') {
    //       t = setTimeout(function() {
    //         $nextProjectDummy.addClass('in-view');
    //         Waypoint.refreshAll();
    //       }, 250);
    //     } else {
    //       clearTimeout(t);
    //       $nextProjectDummy.removeClass('in-view');
    //     }
        
    //     setTimeout(function() {
    //       Waypoint.refreshAll();
    //     });
    //   },
    //   offset: '100%'
    // });
    
    // new Waypoint({
    //   element: $nextProjectDummy[0],
    //   handler: function(direction) {
    //     if (direction == 'down') {
    //       if ($nextProjectDummy.hasClass('in-view')) {
    //         nextPageType = 'next-project';
    //         Barba.Pjax.goTo($(".js-next-project").attr('href'));
    //       }
    //     }
    //   },
    //   offset: 'bottom-in-view'
    // });
    
    function activateSection(newSection) {
      $projectText.removeClass('is-previous');
      $projectText.filter("[data-section='" + section + "']").addClass('is-previous');
      
      section = newSection;
      
      $projectText.removeClass('is-current').removeClass('is-transitioning');
      $projectText.filter("[data-section='" + section + "']").addClass('is-current');
      
      $projectSectionNavItem.removeClass('is-current');
      $projectSectionNavItem.filter("[data-section='" + section + "']").addClass('is-current');
    }
  }
  
  function initScrollNav() {
    $(window).off('DOMMouseScroll mousewheel').on('DOMMouseScroll mousewheel', function (e) {
      
        var direction = 'up';
        
        if (e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0) {
          direction = 'down';
        }
      
      if (! transitioning) {
        
        let $scrollLink = $(".scroll-link--" + direction);
        
        if ($scrollLink.length && $scrollLink) {
          nextPageType = $scrollLink.attr('data-page-type') || 'default';
          
          Barba.Pjax.goTo($scrollLink.attr('href'));
        }
          
        setTimeout(function() {
          $siteWrapper.removeClass('scroll-up').removeClass('scroll-down').addClass('scroll-' + direction);
        });
        
      }
      
    });
  }
  
  function initBarba() {
    
    initBarbaTransitions();
    
    Barba.Pjax.Dom.containerClass = 'ccm-page';
    Barba.Pjax.Dom.wrapperId = 'ccm_page_wrapper';
    Barba.Pjax.start();
    
    Barba.Dispatcher.on('linkClicked', function(el) {
      nextPageType = $(el).attr('data-page-type') || 'default';
    });
    
    Barba.Dispatcher.on('initStateChange', function(currentStatus) {
      if (site.isLoggedIn) {
        window.location = currentStatus.url;
      } else {
      
        transitioning = true;
        Waypoint.destroyAll();
        
        if (activeTransition) {
          $siteWrapper.removeClass('transition-' + activeTransition);
        } else {
          $siteWrapper.removeClass('transition-intro');
        }
        
        activeTransition = pageType + '-to-' + nextPageType;
        
        $siteContainer.removeClass('transition-in').addClass('transition-out');
        $siteWrapper.addClass('transition-' + activeTransition);
      }
    });
    
    Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container){
      $siteContainer = $(container);
      nextPageType = pageType;
    });
    
    Barba.Dispatcher.on('transitionCompleted', function(){
      setTimeout(function() {
        $siteContainer.addClass('transition-in');
      });
      
      setTimeout(function() {
        transitioning = false;
      }, transitions[activeTransition].durationIn);
      
      initPageType();
    });
  }
  
  function initBarbaTransitions() {
    
    Barba.Pjax.getTransition = function() {
      
      return Barba.BaseTransition.extend({
        start: function() {
          let _this = this;
          
          setTimeout(function() {
            _this.newContainerLoading.then(_this.finish.bind(_this));
          }, transitions[activeTransition].durationOut || transitions['default'].durationOut);
        },
      
        finish: function() {
          let _this = this;
          
          preload($(this.newContainer), function() {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            _this.done();
          });
          
        }
      });
    };
  }
  
  function preload($container, callback) {
    
    let $images = $container.find(".js-preload");
    
    if ($images.length) {
      
      var imagesToPreload = 0;
      
      $images.each(function() {
        
        imagesToPreload++;
      
        let img = new Image(),
            $this = $(this);
        
        img.src = $this.attr('src') || $this[0].style.backgroundImage.slice(4, -1).replace(/"/g, "");
        
        img.onload = function() {
          
          imagesToPreload--;
          
          if (imagesToPreload == 0) {
            callback();
          }
        };
        
      });
      
    } else {
      callback();
      
    }
  }
  
});